<template>
<div class="page-cotizaciones">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Cotizaciones" class="elevation-1 px-5 py-3">

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" @click.native="agregar" title="Nueva Cotización" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                <template slot="fecha" slot-scope="props">
                  {{get_fecha_mx(props.row.fecha)}}
                </template>
                <template slot="articulos" slot-scope="props">
                  {{props.row.articulos.length}}
                </template>
                <template slot="total_cotizacion" slot-scope="props">
                  <div style="text-align:right;">$ {{formatNumber(props.row.total_cotizacion)}}</div>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="get_registro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="eliminar(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="success" @click.native="descargaExcel(props.row._id, props.row.nombre)" title="Excel" v-tippy>
                        <v-icon>mdi-file-excel</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="95%">
      <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Agregar Artículos a la Cotización</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-container fluid fill-height>
              <v-layout row wrap>
                  <v-flex col xs6 s6 m6 l6>
                      <h4><v-icon>list</v-icon> Artículos Activos</h4><br>
                      <v-row>
                        <v-col sm="8" md="8" lg="8">
                            <v-text-field label="Producto a buscar" v-model="nombre_filtro"
                            @input="nombre_filtro = nombre_filtro.toString().toUpperCase()"
                            @keyup.enter="buscar_por_nombre"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="2" md="2" lg="2"><br>
                            <v-btn small dark color="blue darken-4"
                                @click="buscar_por_nombre"
                                title="Buscar" v-tippy> Buscar
                            </v-btn>
                        </v-col>
                    </v-row>
                      <v-row>
                        <v-col sm="8" md="8" lg="8">
                            <v-autocomplete v-model="categorias_filtro" :items="categorias"
                                :hide-no-data="true" :loading="loadingCategorias" ref="selectcategoria"
                                placeholder="Ingrese nombre de categoría para buscar"
                                multiple small-chips deletable-chips
                                clearable item-text="nombre" item-value="nombre"
                                label="Categoría" >
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="2" md="2" lg="2"><br>
                            <v-btn small dark color="blue darken-4"
                                @click="buscar_por_categoria"
                                title="Buscar" v-tippy> Buscar
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="8" md="8" lg="8">
                            <v-text-field label="Código" v-model="codigo_filtro"
                            @input="codigo_filtro = codigo_filtro.toString().toUpperCase()"
                            @keyup.enter="buscar_por_codigo"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="2" md="2" lg="2"><br>
                            <v-btn small dark color="blue darken-4"
                                @click="buscar_por_codigo"
                                title="Buscar" v-tippy> Buscar
                            </v-btn>
                        </v-col>
                    </v-row>
                        <v-divider />
                      <v-card-text>
                      <v-client-table id="table_izquierda" ref="vuetable_articulos_all" :data="articulos_encontrados" :columns="columns_lista" :options="options_lista" class="elevation-2 pa-2">
                        <template slot="nombre" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="seleccionar(props.row)">{{props.row.nombre}}</div>
                          </template>
                          <template slot="precio" slot-scope="props">
                            <div style="text-align:right;font-size:10px !important;" @dblclick="seleccionar(props.row)">${{formatNumber(props.row.precio)}}</div>
                          </template>
                          <template slot="descripcion" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="seleccionar(props.row)">{{props.row.descripcion}}</div>
                          </template>
                          <template slot="existencia" slot-scope="props">
                            <div style="text-align:center;font-size:10px !important;" @dblclick="seleccionar(props.row)">
                                <v-chip color="blue" label outlined x-small @click="mostrarExistencias(props.row)">
                                    &nbsp;{{formatNumber(parseFloat(props.row.existencia).toFixed(2))}}&nbsp;
                                </v-chip>
                            </div>
                          </template>
                        <template slot="actions" slot-scope="props">
                          <div style="text-align:center;">
                              <v-icon x-small color="success" @click.native="seleccionar(props.row)">fa-forward</v-icon>
                          </div>
                        </template>
                      </v-client-table>
                    </v-card-text>
                  </v-flex>

                  <v-flex col xs6 s6 m6 l6 style="border-left: 1px solid; !important" >
                      <h4><v-icon>check</v-icon> Artículos en Cotización </h4>
                      <v-row>
                        <v-col sm="8" md="8" lg="8">
                            <v-text-field label="Nombre" v-model="model.nombre" ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="8" md="8" lg="8">
                            <v-text-field label="Dirección" v-model="model.direccion" ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="8" md="8" lg="8">
                            <v-text-field label="Municipio" v-model="model.municipio" ></v-text-field>
                        </v-col>
                        <v-col sm="2" md="2" lg="2">
                            <v-text-field label="C.P." v-model="model.codigo_postal" ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-card-text>
                            <v-row dense>
                              <v-col style="font-weight:bolder;text-align:center !important;">
                                <v-btn outlined small dark color="blue darken-2" 
                                  @click.native="actualizarPrecios()">
                                    <v-icon>refresh</v-icon> Actualizar Precios
                                </v-btn> &nbsp;&nbsp;&nbsp;&nbsp;
                              </v-col>
                              <v-col style="font-weight:bolder;text-align:right !important;">
                                <h3> TOTAL: ${{formatNumber(this.model.total_cotizacion)}} </h3>
                              </v-col>
                            </v-row>                            
                          <v-client-table :key="keyTableArticulos" id="table_derecha" ref="vuetable_articulos_proveedor" :data="model.articulos" :columns="columns_lista_derecha" :options="options_lista_derecha" class="elevation-2 pa-2">
                            <template slot="nombre" slot-scope="props">
                              <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.nombre}}</div>
                            </template>
                            <template slot="cantidad" slot-scope="props">
                              <div style="text-align:right;font-size:10px !important;" @dblclick="remover_articulo(props.row)">
                                <v-chip color="blue" label outlined x-small @click="editarCantidad(props.row)">
                                    &nbsp;&nbsp;{{ props.row.cantidad }}&nbsp;&nbsp;
                                </v-chip>
                                </div>
                            </template>
                            <template slot="descripcion" slot-scope="props">
                              <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.descripcion}}</div>
                            </template>
                            <template slot="precio" slot-scope="props">
                                <div style="text-align:right;font-size:10px !important;" @dblclick="remover_articulo(props.row)">
                                    <v-chip color="blue" label outlined x-small @click="mostrarPrecios(props.row)">
                                        &nbsp;${{formatNumber(props.row.precio)}}&nbsp;
                                    </v-chip>
                                </div>
                            </template>
                            <template slot="total" slot-scope="props">
                                <div style="text-align:right;font-size:10px !important;" @dblclick="remover_articulo(props.row)">${{formatNumber(props.row.total)}}</div>
                            </template>
                          <template slot="actions" slot-scope="props">
                            <div style="text-align:center;">
                                <v-icon x-small color="error" @click.native="remover_articulo(props.row)">fa-backward</v-icon>
                            </div>
                          </template>
                        </v-client-table>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click.native="modal = false">
                            <v-icon>cancel</v-icon> Cerrar
                        </v-btn>
                        <v-btn v-show="update==false" color="warning" @click.native="model = clean_model()">
                            <v-icon>mdi-eraser</v-icon> Limpiar
                        </v-btn>
                        <v-btn :disabled="(model.articulos == null || model.articulos.length == 0)"
                            color="info" @click.native="update ? actualizar() : guardar()">
                            <v-icon>done</v-icon> Guardar
                        </v-btn>
                        <v-btn :disabled="(model.articulos == null || model.articulos.length == 0)"
                            color="success" @click.native="update ? actualizar('print') : guardar('print')">
                            <v-icon>mdi-file-excel</v-icon> Excel
                        </v-btn>
                    </v-card-actions>
                  </v-flex>
              </v-layout>
          </v-container>
  </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modalCantidad" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Cantidad</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCantidad = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="4" md="4" lg="4" style="text-align:center !important;">
                                <v-text-field label="Cantidad" v-model="cantidad_editable"
                                ref="ingresacantidad" v-on:keydown.enter.prevent='1'
                                @keyup.enter="validarArticulo()" :rules="[validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn @click.native="modalCantidad = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn color="success" @click.native="validarArticulo()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modal_precios" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Precios del Artículo</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modal_precios = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-client-table ref="vuetablePrecios" :data="precios_venta_articulo"
                            :columns="columnsPrecios" :options="optionsPrecios">
                            <template slot="precio_con_impuestos" slot-scope="props">
                                <div style="text-align:right;">${{ formatNumber(parseFloat(props.row.precio_con_impuestos).toFixed(2)) }}</div>
                            </template>
                            <template slot="de" slot-scope="props">
                                <div style="text-align:right;">{{props.row.de}}</div>
                            </template>
                            <template slot="a" slot-scope="props">
                                <div style="text-align:right;">{{props.row.a}}</div>
                            </template>

                        </v-client-table>
                        <v-row justify="center">
                            <v-btn @click.native="modal_precios = false">
                                <v-icon>cancel</v-icon> Cerrar
                            </v-btn>
                        </v-row>
                    </v-container>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modal_existencia" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Existencias del Artículo</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modal_existencia = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <strong>Existencia Total: </strong> {{row_selected.existencia}}</legend>
                        <v-client-table ref="vuetableexistencias" :data="articulo_existencias"
                            :columns="columnsExistencias" :options="optionsExistencias">
                            <template slot="existencia" slot-scope="props">
                                <div style="text-align:right;" >{{ formatNumber(parseFloat(props.row.existencia).toFixed(2))}}</div>
                            </template>
                        </v-client-table>
                        <v-row justify="center">
                            <v-btn @click.native="modal_existencia = false">
                                <v-icon>cancel</v-icon> Cerrar
                            </v-btn>
                        </v-row>
                    </v-container>
            </v-card-text>

        </v-card>
    </v-dialog>


</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
    search(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length < 2) return
      // Si todavía esta buscando
      if (this.isLoading) return
      this.isLoading = true
      let data = {
        "selector": {
          "type":"articulos",
          "nombre": {
            "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          },
          "estatus": "Activo"
        },
        "sort": ["nombre"],
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.articulos_encontrados = response.data.docs;
          } else
            this.articulos_encontrados = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los articulos.",
            footer: ""
          });
        }).then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    filteredItems() {
      return this.articulos_all.filter(item => {
         return item.nombre.toLowerCase().indexOf(this.filtro_articulo.toLowerCase()) > -1
      })
    }
  },
  mounted: function() {
    if (!this.verificaPermiso('t.cotizaciones.crear')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    this.model_filters = this.$refs.vuetable.$refs.table.query;
  },
  created: function() {
    window.dialogLoader.show('Espere un momento por favor..');
    this.model = this.clean_model();
    this.getCategorias();
    this.get_sucursales();
    this.getArticulosActivos().then(result => {
      window.dialogLoader.hide();
    }).catch(error => {
        window.dialogLoader.hide();
        this.$swal({
          type: "error",
          title: "¡Operación no Permitida!",
          text: "Ocurrió un error al obtener los artículos.",
          footer: error
        });
      });
  },
  data() {
    return {
        keyTableArticulos:"keyInicial",
        columnsExistencias: ["sucursal","existencia"],
        optionsExistencias: {
            filterable: false,
        },
        sucursales:[],
        articulo_existencias:[],
        modal_existencia:false,
        columnsPrecios: ["tipo_precio","precio_con_impuestos","de","a"],
        optionsPrecios:{
            filterable: false,
            headings:{
                "precio_con_impuestos":"Precio Venta Neto",
            },
        },
        precios_venta_articulo: [],
        modal_precios: false,
        row_selected:{},
        cantidad_editable:"",
        modalCantidad: false,
        modal_proveedor:false,
      categorias_filtro:[],
      nombre_filtro:"",
      codigo_filtro:"",
      categorias:[],
      filtro_articulo:"",

      proveedores_all:[],
      articulos_all:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      search: "",
      model_filters: "",
      columns_lista: [
          "nombre","precio","descripcion","existencia",
          "actions"
        ],
      columns_lista_derecha: [
          "actions",
          "nombre","cantidad","descripcion","precio","total"
      ],
      options_lista: {
        filterable: ["nombre","precio","descripcion","existencia"],
        sortable: ["nombre","precio","existencia","descripcion"],
        perPage: 15,
        pagination: {
          show: true
        },
        headings: {
          nombre: "Artículo",
          precio:"Precio Público",
          "descripcion": "Unidad",
          actions: ""
        },
      },
      options_lista_derecha: {
        filterable: ["nombre", "descripcion","cantidad","precio"],
        sortable: ["nombre", "descripcion","cantidad","precio"],
        perPage: 15,
        pagination: {
          show: true
        },
        headings: {
          nombre: "Artículo",
          "descripcion": "Unidad",
          precio:"Precio Unitario",
          actions: ""
        },
      },

      articulos: {
        columns: [
          "codigo_barras",
          "nombre",
          "descripcion",
          "actions"
        ],
        options: {
          filterable: ["codigo_barras", "nombre", "marca"],
          sortable: ["codigo_barras", "nombre", "marca"],
          perPage: 10,
          pagination: {
            show: true
          },
          headings: {
            "codigo_barras": "Código",
            nombre: "Nombre",
            "marca": "Marca",
            actions: "Operaciones"
          },
        },
        items: []
      },
      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Cotizaciones",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "fecha",
        "usuario.nombre",
        "nombre",
        "articulos",
        "total_cotizacion",
        "actions"
      ],
      options: {
        filterable: ["fecha",
        "usuario.nombre",
        "nombre"],
        sortable: ["fecha",
        "usuario.nombre",
        "nombre","total_cotizacion"],

        headings: {
            "fecha":"Fecha",
            "usuario.nombre":"Usuario",
            "nombre":"Cliente",
            "articulos":"Artículos",
            "total_cotizacion":"Total Cotizado",
            actions: "Operaciones"
        },
      },
      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"cotizaciones"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort:[{"fecha":"desc"}],
            "use_index": "_design/d0ed8fcf1907c73968a278b31f5116e2e93a4566"
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.items = this.items.concat(nuevos);            
            /*for(var k in this.items){
              this.items[k].articulos= 0;
            }*/
            this.props_paginacion.total_registros = this.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    buscar_por_categoria: async function () {
        var self = this;

        if(self.categorias_filtro != null && self.categorias_filtro.length>0){
           window.dialogLoader.show('Espere un momento por favor..');
            var filtro = [];

            filtro = this.articulos_all.filter(function (a) {
                for(var k in a.categoria){
                    return self.categorias_filtro.includes(a.categoria[k]);
                }
            });

            //self.articulos_raw = JSON.parse(JSON.stringify(filtro));
            this.existencia_datos(JSON.parse(JSON.stringify(filtro)));
        }

    },
    buscar_por_nombre: function () {
        var self = this;
        var filtro = [];

        if(self.nombre_filtro != null && self.nombre_filtro.toString().trim()!="" && self.nombre_filtro.toString().trim().length>=2){
            window.dialogLoader.show('Espere un momento por favor..');
            filtro = this.articulos_all.filter(function (a) {
                return a.nombre.toString().toUpperCase().indexOf(self.nombre_filtro.toString().toUpperCase()) != -1;
            });

            //self.articulos_raw = JSON.parse(JSON.stringify(filtro));
            this.existencia_datos(JSON.parse(JSON.stringify(filtro)));
        }


    },
    buscar_por_codigo: function () {
        var self = this;
        var filtro = [];

        if(self.codigo_filtro != null && self.codigo_filtro.toString().trim()!="" && self.codigo_filtro.toString().trim().length>=2){
            window.dialogLoader.show('Espere un momento por favor..');
            filtro = this.articulos_all.filter(function (a) {
                return a.codigo_barras.includes(self.codigo_filtro.toString().toUpperCase());
            });
            
            //self.articulos_raw = JSON.parse(JSON.stringify(filtro));
            this.existencia_datos(JSON.parse(JSON.stringify(filtro)));
        }
    },
    existencia_datos: async function (datos) {
        var self = this;

        await Promise.all(datos.map(async (item) => {
            var datos_consulta = {
                "id_articulo": item._id,
                "id_sucursal": ""
            };

            item.precios_venta.forEach(function (x) {
                if (x.tipo_precio == "Público General"){
                    item["precio"] = parseFloat(x.precio_con_impuestos).toFixed(2);
                }
            });

            await window.funciones_lotes.consultaExistenciaTotal(datos_consulta).then(result => {
                item["existencia"] = result;
                //console.log("Checando existencia");
            }).catch(err => {
                console.log("Ocurrió un error al consultar existencia de artículo.");                
            });
        }));

        self.articulos_encontrados = datos;
        window.dialogLoader.hide();
    },
    mostrarPrecios: function(row){
        this.precios_venta_articulo = row.precios_venta;
        this.modal_precios = true;
    },
    mostrarExistencias: function(row){
        this.row_selected = row;

        var self = this;
        window.funciones_lotes.consultaExistencia({
            "id_articulo": this.row_selected._id
        }).then(result => {
            if (result.length > 0) {

                result.forEach(el => {
                    el["sucursal"] = self.getNombreSucursal(el.id_sucursal);
                });
                this.articulo_existencias = result;
            } else {
                this.articulo_existencias = [];
            }
            this.modal_existencia = true;
            //console.log("se asigno la existencia");

        }).catch(err => {
            console.log("error al buscar existencia", err);
            this.articulo_existencias = [];
            this.modal_existencia = true;
        });
    },
    getNombreSucursal: function(id_sucursal) {
        var filter = this.sucursales.find(e => e._id == id_sucursal);
        if (filter)
            return filter.nombre;
        else
            return "";
    },
    get_sucursales: function () {
        let data = {
            "selector": {
                "nombre":{"$exists":true},
            },
            "fields": ["nombre", "_id"],
            "use_index": "_design/84f098ad708a26e38d78e9daf0da60ca49fd02ea"
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];
                if (response.data.docs.length > 0){
                    this.sucursales = response.data.docs;
                }
            }).catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            });
    },
    formatNumber: function (x) {
        if (x == undefined) return 0;
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
        return x;
    },
    getCategorias() {
        this.loadingCategorias = true;
        let data = {
            "selector": {
                "type": "categorias",
                "estatus": "Activo"
            },
            "use_index": "_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                response.data.docs.sort(this.ordenar_nombre);
                this.categorias = response.data.docs;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las categorias.",
                    footer: ""
                });
            }).then(() => {
                this.loadingCategorias = false;
            });
    },
    ordenar_nombre: function (a, b) {
        if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
            return -1;
        }
        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
            return 1;
        }
        return 0;
    },
    clean_model: function() {
        this.update = false;
        return {
            _id: "",
            _rev: "",
            type: "cotizaciones",
            nombre: "",
            direccion: "",
            municipio: "",
            codigo_postal: "",
            articulos:[],
            total_cotizacion:0,
            fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
            usuario:{                
                "nombre":this.$local_storage.get("sb_nombre_usuario", "" ),
                "email": this.$local_storage.get("sb_usuario", "" )
            },
        };
    },

    get_registro: function(id) {
      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id)
        .then(response => {
            this.model = response.data;
            this.update = true;
            this.modal = true;
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },

    agregar: function() {
      this.model = this.clean_model();
      this.modal = true;
    },

    editar_articulos: function() {
      this.update = true;
      this.modal_articulos = true;
      this.search = "";
    },
    guardar: function(opcion="save") {
      if (this.model.articulos != null && this.model.articulos.length > 0) {

        window.dialogLoader.show('Espere un momento por favor..');

          let data = this.model;
          delete data["_id"];
          delete data["_rev"];
          data["total_cotizacion"] = parseFloat(data["total_cotizacion"]);

          window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
            .then(response => {

                if(opcion == "print"){
                    this.descargaExcel(response.data.id, data["nombre"]);
                } else {
                    window.dialogLoader.hide();
                    this.modal = false;
                    window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                        color: 'success'
                    });
                    this.model = this.clean_model();
                    this.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                }
            })
            .catch(error => {
              console.log(error);
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                footer: ""
              });
              window.dialogLoader.hide();
            });

      }
    },

    actualizar: function(opcion="save") {
      if (this.model.articulos != null && this.model.articulos.length > 0) {
        window.dialogLoader.show('Espere un momento por favor..');

          let data = this.model;
          data["total_cotizacion"] = parseFloat(data["total_cotizacion"]);

          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
            .then(response => {
                if(opcion == "print"){
                    this.descargaExcel(data["_id"], data["nombre"]);
                } else {
                    window.dialogLoader.hide();
                    this.modal = false;
                    window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                        color: 'success'
                    });
                    this.model = this.clean_model();
                    this.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                }
            })
            .catch(error => {
                window.dialogLoader.hide();
                this.modal = false;
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al actualizar el registro. Intente nuevamente.",
                    footer: ""
                });
                this.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            });

      }
    },
    eliminar: async function(_id, _rev) {
        let self = this;

        this.$swal({
            type: "info",
            title: "Cuidado",
            text: "¿Está seguro de eliminar el registro?",
            footer: "",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value ) {
                window.dialogLoader.show('Espere un momento por favor..');
                window.axios
                    .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
                    .then(response => {
                        window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                            color: 'success'
                        });
                        this.items = [];
                        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    }).catch(error => {
                        window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                            color: 'error'
                        });
                    }).then(() => {
                        window.dialogLoader.hide();
                    });

            }

        });


    },

    validaCantidadEntera: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad entera no válida"
      } else
        return true;
    },
    get_fecha: function(fecha) {
      try {
        return window.moment(fecha).format("YYYY-MM-DD");
      } catch (e) {
        return "";
      }
    },

    get_fecha_mx: function(fecha) {
      try {
        return window.moment(fecha).format("DD-MM-YYYY HH:mm");
      } catch (e) {
        return "";
      }
    },
    validaCantidadFloatRequerido: function (value) {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        if (value != null && value != "" && !pattern.test(value)) {
            return "Cantidad no válida"
        } else if (value != null && value != "") {
            if (parseFloat(value) <= 0)
                return "Debe ser mayor que cero";
        } else if (value != null && value == "")
            return "Campo requerido";
        return true;
    },
    editarCantidad:function(row){
        this.row_selected = row;
        this.cantidad_editable = row.cantidad;
        this.modalCantidad = true;
        setTimeout(() => {
            this.$nextTick(() => this.$refs.ingresacantidad.focus());
        }, 100);
    },
    validarArticulo: function () {
        var self = this;
        if (this.$refs.formcantidad.validate()) {

            this.model.articulos.forEach(function(row) {
                if(row._id == self.row_selected._id){
                    var precio = self.get_precio_venta(parseFloat(self.cantidad_editable), self.row_selected.precios_venta);                    
                    row["cantidad"] = parseFloat(self.cantidad_editable);
                    row["total"] = parseFloat(self.cantidad_editable) * parseFloat(precio);
                    row["total"] = row["total"].toFixed(2);
                    row["precio"] = parseFloat(precio).toFixed(2);
                }
            } );
            this.calcular_total();
            this.modalCantidad = false;
        }
    },
    get_precio_venta:function(cantidad, precios){
        try {
            var importe = 0;
            for( var k in precios){
                if (cantidad >= precios[k]["de"] && cantidad <= precios[k]["a"]){
                    importe = precios[k]["precio_con_impuestos"];
                    break;
                }
            }
            if(importe == 0){ //Si no encontró un precio según la cantidad, que busque el mas cercano                
                try {
                  var limites = []
                  for(var k in precios){
                    var precio = precios[k];
                    limites.push({"precio":precio, "distancia": Math.abs(Math.round(parseFloat(cantidad)) - parseFloat(precio["de"]))})
                    limites.push({"precio":precio, "distancia": Math.abs(Math.round(parseFloat(cantidad)) - parseFloat(precio["a"]))})
                  }                                                             
                  var limites_sort = limites.sort((a, b) => a.distancia - b.distancia);//De menor a mayor distancia
                  importe = parseFloat(limites_sort[0]["precio"]["precio_con_impuestos"])
                } catch (error) {
                  console.log(error);
                    //Si todo falla que busque el publico en general                    
                    var publico = precios.find(a => a.tipo_precio == "Público General");
                    if (publico){
                        importe = parseFloat(publico["precio_con_impuestos"])
                    }else{ //Si no encontro el del publico, que tome el primero
                        importe = parseFloat(precios[0]["precio_con_impuestos"])
                    }
                }
                
                    
            }

            return importe;

        } catch (error) {
            console.log("Error al buscar precio", error);
            return importe;
        }
    },
    actualizarPrecios:function(){
      var self = this;
      var filtro = [];

      var articulosmap = this.model.articulos.map(a => a._id);
      this.getArticulosActivos().then(result => {
        
          window.dialogLoader.show('Espere un momento por favor..');

          filtro = self.articulos_all.filter(function (a) {
              return articulosmap.includes(a._id);
          });

          self.existencia_datos(JSON.parse(JSON.stringify(filtro))).then( r => {

              self.articulos_encontrados.forEach( x => {
                var existe = self.model.articulos.find(a => a._id == x._id);
                if(existe) {
                    existe["existencia"] = x["existencia"];
                    var precio = self.get_precio_venta(parseFloat(existe["cantidad"]), x.precios_venta);
                    existe["total"] = parseFloat(existe["cantidad"]) * parseFloat(precio);
                    existe["total"] = existe["total"].toFixed(2);
                    existe["precio"] = parseFloat(precio).toFixed(2);
                }
              });

              self.calcular_total();
              
          });
        

        
        window.dialogLoader.hide();
      }).catch(error => {
          window.dialogLoader.hide();
          self.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los artículos.",
            footer: error
          });
        });
    },
    remover_articulo: function(row) {
        var new_arti = this.model.articulos.filter(x => x._id != row._id);
        this.model.articulos = new_arti;
        this.calcular_total();
    },
    seleccionar: function(row) {
        let existe = this.model.articulos.filter(x => x._id == row._id);

        if(existe.length <= 0) {
            row["cantidad"] = 1;
            row["total"] = row.precio;
            this.model.articulos.push(row);
            this.calcular_total();
        }
    },
    calcular_total: function(){
        var total = 0;
        for(var k in this.model.articulos){
            var art = this.model.articulos[k];
            total += parseFloat(art["total"]);
        }
        this.model.total_cotizacion = total.toFixed(2);
        this.keyTableArticulos = moment(new Date()).format("x"); //Refrescar tabla
    },    

    getArticulosActivos: function() {
      var self = this;
      return new Promise(function(resolve, reject) {
        window.dialogLoader.show('Espere un momento por favor..');

        var data = {
        "selector": {"type":"articulos","estatus":"Activo"},
        "fields":["_id", "nombre", "descripcion","codigo_barras","precios_venta","categoria"],
        "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.articulos_all = response.data.docs;
            } else
              self.articulos_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          }).then(()=>{
            window.dialogLoader.hide();
          });
      });
    },
   
    validaArticuloImport: function(val) {
      var filter = this.articulos_all.find(function(e){
          return  e.codigo_barras.includes(val.toString().toUpperCase().trim());
        }
      );
      if (filter){
        return filter;
      } else
        return "";
    },
    descargaExcel(id_cotizacion, cliente) {
            var self = this;
            window.dialogLoader.show('Espere un momento por favor..');

            let datos = {"id_cotizacion":id_cotizacion};
            window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/excel_cotizacion/", datos, {
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                })
                .then(response => {
                    var bytes = response.data;
                    let blob = new Blob([bytes], {
                        type: response.headers["content-type"]
                        }),
                        url = window.URL.createObjectURL(blob);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = "Cotizacion_" + cliente+".xlsx";
                    a.click();
                    a.parentNode.removeChild(a);
                })
                .catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el excel. Intente nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    window.dialogLoader.hide();
                    this.modal = false;
                    this.model = this.clean_model();
                    this.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });

        },

  }
}
</script>
<style scoped>

</style>
